<template>
    <div>
        <div>
            <bread-crumb :crumbData="['操作日志']" :chooseCrumbData="1"></bread-crumb>
            <el-row style="margin: 20px">
                <el-col :span="9">
                    <span>创建日期：</span>
                    <el-date-picker v-model="t1" type="daterange" range-separator="至" start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                </el-col>
                <el-col :span="6">
                    <span>订单状态：</span>
                    <el-select v-model="query.status" clearable filterable placeholder="订单状态">
                        <el-option v-for="item in status_list" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-col>

                <el-col :span="7">
                    <el-button type="success" size="mini" style="margin-left: 5px" @click="handleQuery()">
                        <div style="display: flex; align-items: center; font-size: 14px">
                            <img style="width: 16px; height: 16px" :src="require('@/assets/iconImg/logo.png')" />
                            <span style="margin-left: 3px">查询</span>
                        </div>
                    </el-button>
                </el-col>
            </el-row>
            <div style="padding: 20px">
                <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" border style="width: 100%">
                    <el-table-column prop="create_time" sortable label="创建时间"></el-table-column>
                    <el-table-column prop="column" sortable label="栏目"></el-table-column>
                    <el-table-column prop="status" sortable label="状态"></el-table-column>
                    <el-table-column prop="operate" sortable label="操作类型"></el-table-column>
                    <el-table-column prop="operator" sortable label="操作员"></el-table-column>
                    <el-table-column prop="notes" sortable label="操作说明"></el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button @click="handleConfirm(scope.row)"
                                :disabled="scope.row.status == '已确认'">确认</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <pagination :handleQuery="handleQuery" :currentPage="query.currentPage" :currentLength="query.currentLength"
                    :total="total">
                </pagination>
            </div>
        </div>
    </div>
</template>
<script>
import pagination from "@/components/pagination/index.vue";
import { getDate } from "@/until/utilse.js";
export default {
    components: {

        pagination
    },
    data() {
        return {
            t1: [],
            total: 0,
            tableData: [],
            commercial_id: localStorage.getItem("commercial_id") ||
                sessionStorage.getItem("commercial_id"),
            query: {
                firstTime: "",
                lastTime: "",
                currentPage: 1,
                currentLength: 20,
                status: null,
            },
            status_list: [{
                value: 0,
                label: "未确认"
            }, {
                value: 1,
                label: "已确认"
            }],
        };
    },
    mounted() {
        this.handleQuery()
    },
    watch: {

    },
    computed: {},
    methods: {
        async handleQuery(page = 1) {
            let query = this.query;
            let n = this.t1
            if (n?.[0]) {
                this.query.firstTime = getDate(n[0])
            } else {
                this.query.firstTime = null
            }
            if (n?.[1]) {
                this.query.lastTime = getDate(n[1])
            } else {
                this.query.lastTime = null;
            }
            let res = (await this.$http.post("/system/Operation_Log/lst", query)).data
            this.total = res.count;
            this.tableData = res.data.map(el => {
                el.status = this.status_list[el.status].label
                return el
            });

            console.log(this.tableData);
        },
        async handleConfirm({ id }) {
            let res = (await this.$http.post("/system/Operation_Log/edit", { id })).data;
            if (res.status == "success") {
                this.$message.success("操作成功")
            } else {
                this.$message("没有权限")
            }
        }
    }
};
</script>
<style scoped lang="less"

/deep/ .cell {
    padding: 0 !important;
}

</style>